import React from 'react';

function App() {
  const subdomain = window.location.hostname.split('.')[0];

  return (
      <div>
          {subdomain === 'admin' && <div>admin page</div>}
          {subdomain === 'user' && <div>user page</div>}
      </div>
  );
}

export default App;
